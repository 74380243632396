import { useCallback } from 'react';
import { optimizerApi } from '../services/api';

const _tempNps = '/temporary-nps';

export const useOptimizerApi = () => {
  const shouldExecuteTemp = useCallback(async () => {
    try {
      const res = await optimizerApi.get(`${_tempNps}/can-execute`);
      return String(res.data) === 'true';
    } catch (_) {
      return false;
    }
  }, []);

  const completeSurveyTemp = useCallback(async () => {
    try {
      await optimizerApi.post(`${_tempNps}/mark-survey-as-completed`);
    } catch (_) {
      console.log('Error complete survey');
    }
  }, []);

  const skipSurveyTemp = useCallback(async () => {
    try {
      await optimizerApi.post(`${_tempNps}/mark-today-as-skiped`);
    } catch (_) {
      console.log('Error skip survey');
    }
  }, []);

  return {
    shouldExecuteTemp,
    completeSurveyTemp,
    skipSurveyTemp,
  };
};
